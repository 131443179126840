import Title from '../components/Title'
import SmallMenu from '../components/SmallMenu/SmallMenu'
import FaqList from '../components/Faq/FaqList'
import Config from '../config/config.json'

import { Trans, useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'
import { Link } from 'react-router-dom'

function Faq() {
  const { t } = useTranslation()
  useDocumentTitle(`${t('tiles.faq')} | Příběh těla`)

  const data = []
  for (let i = 0; i < Config.faqItems; i++) {
    data.push({
      question: t(`faq.${i}.question`),
      answer: (
        <Trans
          t={t}
          i18nKey={`faq.${i}.answer`}
          components={{
            reservations: (
              <Link
                to={
                  Config.tiles.find((t) => t.title === 'reservations')?.href ||
                  '/'
                }
                target="_blank"
                className="underline text-secondary"
              />
            ),
            phone: (
              <Link
                to={`tel:${Config.phone}`}
                className="underline text-secondary"
              />
            ),
            email: (
              <Link
                to={`mailto:${Config.email}`}
                className="underline text-secondary"
              />
            ),
            pricing: (
              <Link
                to={
                  Config.tiles.find((t) => t.title === 'pricing')?.href || '/'
                }
                className="underline text-secondary"
              />
            ),
            contact: (
              <Link
                to={
                  Config.tiles.find((t) => t.title === 'contact')?.href || '/'
                }
                className="underline text-secondary"
              />
            ),
          }}
        ></Trans>
      ),
    })
  }

  return (
    <Title goBack title={t('tiles.faq')}>
      <div className="flex flex-col px-8 md:px-32">
        <FaqList items={data} />
        <SmallMenu except={['faq', 'reservations']} />
      </div>
    </Title>
  )
}

export default Faq
