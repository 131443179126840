import Tile from '../components/Tile'
import Title from '../components/Title'
import Config from '../config/config.json'

import { useDocumentTitle } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'

function Home() {
  const { t } = useTranslation()
  useDocumentTitle(`Příběh těla`)

  return (
    <Title>
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-row flex-wrap justify-center gap-24 max-w-6xl px-10 sm:px-20">
          {Config.tiles.map((tile) => (
            <Tile
              key={tile.title}
              image={tile.image}
              title={t(`tiles.${tile.title}`)}
              href={tile.href}
              target={tile.target}
            />
          ))}
        </div>
      </div>
    </Title>
  )
}

export default Home
