import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const LoaderContext = createContext({
  load: (idx: string | string[]) => {},
  solve: (idx: string | string[]) => {},
  isLoading: true,
})

interface ElementStateObject {
  loading: string[]
  completed: string[]
}

export const LoaderProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const [elements, setElements] = useState<ElementStateObject>({
    loading: [],
    completed: [],
  })

  const load = (idx: string | string[]) => {
    setElements((prev) => {
      const isCompleted = Array.isArray(idx)
        ? idx.every((el) => prev.completed.includes(el))
        : prev.completed.includes(idx)

      if (isCompleted) return prev
      else {
        return {
          ...prev,
          loading: Array.isArray(idx)
            ? [...prev.loading, ...idx]
            : [...prev.loading, idx],
        }
      }
    })
  }
  const solve = (idx: string | string[]) => {
    setElements((prev) => {
      return {
        completed: Array.isArray(idx)
          ? [...prev.completed, ...idx]
          : [...prev.completed, idx],
        loading: prev.loading.filter((el) =>
          Array.isArray(idx) ? !idx.includes(el) : el !== idx
        ),
      }
    })
  }

  useEffect(() => {
    setElements((prev) => {
      return { ...prev, completed: [] }
    })
  }, [location.pathname])

  useEffect(() => {
    if (elements.loading.length)
      console.log('Loading:', elements.loading.toString())
    if (elements.completed.length)
      console.log('Completed:', elements.completed.toString())
  }, [elements])

  const contextValue = {
    load,
    solve,
    isLoading: elements.loading.length > 0,
  }

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  )
}
