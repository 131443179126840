import { ImgHTMLAttributes, useMemo } from 'react'
import { useLoader } from '../hooks/useLoader'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

function Image(props: ImageProps) {
  const index = useMemo(() => {
    return (Math.random() + 1).toString(36).substring(7)
  }, [])
  const loader = useLoader(index)

  return (
    <img
      alt={index}
      {...props}
      onLoad={() => loader.solve(index)}
      onError={() => loader.solve(index)}
    />
  )
}

export default Image
