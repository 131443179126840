import Config from '../config/config.json'

import { useTranslation } from 'react-i18next'
import { LuCalendarCheck } from 'react-icons/lu'
import { Link } from 'react-router-dom'

function AppointmentButton() {
  const { t } = useTranslation()

  return (
    <Link
      to={
        Config.tiles.find((tile) => tile.title === 'reservations')?.href ?? '/'
      }
      target="_blanc"
      className="flex gap-3 text-primary bg-secondary items-center justify-center p-5 rounded-xl cursor-pointer"
    >
      <LuCalendarCheck className="text-xl" />
      <span>{t('others.makeAnAppointment')}</span>
    </Link>
  )
}

export default AppointmentButton
