export interface PricingItemProps {
  title: string
  price: string
  duration: string
  description: string
  align: 'left' | 'right'
}

function PricingItem(props: PricingItemProps) {
  return (
    <>
      <div className="gap-3 w-full justify-center mb-10 hidden md:flex">
        {props.align === 'right' && (
          <div className="relative p-4 md:p-6 border border-secondary rounded-3xl w-1/2 md:w-2/3 top-10 h-fit">
            {props.description}
          </div>
        )}
        <div className="flex flex-col items-center justify-center p-5 md:p-10 bg-primary rounded-3xl text-lg text-secondary max-h-48">
          <span>{props.title}</span>
          <span>{props.duration}</span>
          <span>{props.price}</span>
        </div>
        {props.align === 'left' && (
          <div className="relative p-4 md:p-6 border border-secondary rounded-3xl w-1/2 md:w-2/3 top-10 h-fit">
            {props.description}
          </div>
        )}
      </div>
      <div className="flex flex-col w-full justify-center md:hidden gap-3">
        <div className="flex flex-col items-center justify-center p-5 md:p-10 bg-primary rounded-3xl text-lg text-secondary">
          <span>{props.title}</span>
          <span>{props.duration}</span>
          <span>{props.price}</span>
        </div>
        <div className="p-4 md:p-6 border border-secondary rounded-3xl w-full">
          {props.description}
        </div>
      </div>
    </>
  )
}

export default PricingItem
