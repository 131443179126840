import React from 'react'
import Image from '../Image'

import { motion } from 'framer-motion'

export interface FaqItemProps {
  question: string
  answer: string | React.ReactNode
  selected?: boolean
  onClick?: () => void
}

function FaqItem(props: FaqItemProps) {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex justify-between items-center w-full border rounded-xl p-3 cursor-pointer gap-8 ${
          props.selected ? 'border-primary bg-primary' : 'border-secondary'
        }`}
        onClick={props.onClick}
      >
        <span>{props.question}</span>
        <Image
          src="svg/arrow.svg"
          alt="arrow"
          className={`w-5 ${props.selected ? '' : '-rotate-90'}`}
        />
      </div>
      {props.selected && (
        <motion.div
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          transition={{ duration: 0.3, ease: 'linear' }}
          initial="closed"
          animate={props.selected ? 'open' : 'closed'}
          exit="closed"
          className="px-4 py-2"
        >
          {props.answer}
        </motion.div>
      )}
    </div>
  )
}

export default FaqItem
