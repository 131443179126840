import Image from './Image'
import { Link } from 'react-router-dom'

interface TileProps {
  image: string
  title: string
  href: string
  target?: string
}

function Tile({ image, title, href, target }: TileProps) {
  return (
    <Link to={href} target={target ?? '_self'}>
      <div className="relative w-60 h-60 border border-secondary rounded-3xl cursor-pointer">
        <div className="w-full h-full rounded-3xl overflow-hidden">
          <Image
            src={image}
            alt={image}
            className="w-full h-full object-cover z-0"
          ></Image>
        </div>
        <div className="absolute w-36 h-16 bg-primary z-20 top-[12rem] left-[7.5rem] rounded-xl">
          <div className="w-full h-full flex items-center justify-center text-secondary">
            {title}
          </div>
        </div>
        <div className="absolute w-36 h-16 bg-primary/[.54] z-10 top-[12.5rem] left-[8rem] rounded-xl"></div>
      </div>
    </Link>
  )
}

export default Tile
