import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function LanguageSelector() {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language)

  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLang = e.target.value
    setLang(newLang)

    localStorage.setItem('i18nextLng', newLang)
    i18n.changeLanguage(newLang)
  }

  return (
    <select
      className="w-min outline-none text-primary bg-secondary rounded-xl p-1 border-x-4 border-secondary"
      value={lang}
      onChange={handleLangChange}
    >
      {Object.keys(i18n.options.resources ?? {})
        .sort()
        .map((lng) => (
          <option key={lng} value={lng}>
            {lng.toUpperCase()}
          </option>
        ))}
    </select>
  )
}

export default LanguageSelector
