import Title from '../components/Title'
import SmallMenu from '../components/SmallMenu/SmallMenu'
import Line from '../components/Line/Line'
import LineItem from '../components/Line/LineItem'

import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'
import Image from '../components/Image'

function MyApproach() {
  const { t } = useTranslation()
  useDocumentTitle(`${t('tiles.myApproach')} | Příběh těla`)

  return (
    <>
      <Title goBack title={t('tiles.myApproach')}>
        <div className="flex flex-col px-8 md:px-32">
          <div className="w-full flex flex-col gap-16 mb-10">
            <LineItem
              title={
                <div className="relative flex items-center gap-0.5">
                  <Image
                    src="/svg/symbols/quotes.svg"
                    alt="quotes"
                    className="relative w-3 h-3 inline-block -bottom-2.5"
                  />
                  <span>{t('myApproach.0.title')}</span>
                  <Image
                    src="/svg/symbols/quotes.svg"
                    alt="quotes"
                    className="relative w-3 h-3 rotate-180 inline-block -top-1.5"
                  />
                </div>
              }
              indent={2.75}
              align="left"
            >
              {t('myApproach.0.description')}
            </LineItem>
            <LineItem
              title={
                <div className="flex gap-3 items-center">
                  <span>{t('myApproach.1.title')}</span>
                  <Image
                    src="/svg/symbols/infinity.svg"
                    alt="infinity"
                    className="w-8 h-8 inline-block"
                  />
                </div>
              }
              indent={2}
              align="right"
            >
              {t('myApproach.1.description')}
            </LineItem>
            <LineItem
              title={
                <div className="flex items-center gap-1">
                  <span>{t('myApproach.2.title')}</span>
                  <Image
                    src="/svg/symbols/question.svg"
                    alt="question-mark"
                    className="w-8 h-8 inline-block"
                  />
                </div>
              }
              indent={2.3}
              align="left"
            >
              {t('myApproach.2.description')}
            </LineItem>
            <LineItem
              title={
                <div className="flex items-center gap-1">
                  <span>{t('myApproach.3.title')}</span>
                  <Image
                    src="/svg/symbols/exclamation.svg"
                    alt="exclamation-point"
                    className="w-8 h-8 inline-block"
                  />
                </div>
              }
              indent={2}
              align="right"
            >
              {t('myApproach.3.description')}
            </LineItem>
            <LineItem
              title={
                <div className="flex items-center gap-2">
                  <Image
                    src="/svg/symbols/hashtag.svg"
                    alt="hashtag"
                    className="w-6 h-6 inline-block"
                  />
                  <span>{t('myApproach.4.title')}</span>
                </div>
              }
              indent={2.4}
              align="left"
            >
              {t('myApproach.4.description')}
            </LineItem>
          </div>
          <SmallMenu except={['myApproach', 'reservations']} />
          <Line src="/svg/line.svg" />
        </div>
      </Title>
    </>
  )
}

export default MyApproach
