import Image from '../Image'

interface LineProps {
  src: string
}

function Line(props: LineProps) {
  return (
    <div className="absolute -top-28 left-[4.5rem] z-0 lg:inline hidden">
      <Image src={props.src} alt="line" className="w-12" />
    </div>
  )
}

export default Line
