import Image from '../Image'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface SmallMenuItemProps {
  image: string
  title: string
  href: string
  target?: string
}

function SmallMenuItem({ image, title, href, target }: SmallMenuItemProps) {
  const { t } = useTranslation()

  return (
    <Link
      to={href}
      target={target ? target : '_self'}
      key={title + 'SmallMenu'}
    >
      <div className="relative w-32 h-32 border border-secondary rounded-3xl cursor-pointer">
        <div className="w-full h-full rounded-3xl overflow-hidden">
          <Image
            src={image}
            alt={image}
            className="w-full h-full object-cover z-0"
          ></Image>
        </div>
        <div className="absolute w-28 h-10 bg-primary z-20 top-[6.5rem] left-8 rounded-xl">
          <div className="w-full h-full flex items-center justify-center text-secondary text-sm">
            {t(`tiles.${title}`)}
          </div>
        </div>
        <div className="absolute w-28 h-10 bg-primary/[.54] z-10 top-[6.80rem] left-[2.30rem] rounded-xl"></div>
      </div>
    </Link>
  )
}

export default SmallMenuItem
