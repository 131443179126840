import Title from '../components/Title'
import SmallMenu from '../components/SmallMenu/SmallMenu'
import PricingItem, { PricingItemProps } from '../components/PricingItem'
import Config from '../config/config.json'

import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'

function Pricing() {
  const { t } = useTranslation()
  useDocumentTitle(`${t('tiles.pricing')} | Příběh těla`)

  const data: PricingItemProps[] = []
  for (let i = 0; i < Config.pricingItems; i++) {
    data.push({
      title: t(`pricing.${i}.title`),
      duration: t(`pricing.${i}.duration`),
      price: t(`pricing.${i}.price`),
      description: t(`pricing.${i}.description`),
      align: i % 2 === 0 ? 'left' : 'right',
    })
  }

  return (
    <Title goBack title={t('tiles.pricing')}>
      <div className="flex flex-col px-8 md:px-32">
        <div className="flex flex-col gap-10 mb-14 w-full">
          {data.map((item, index) => (
            <PricingItem key={`pricing-${index}`} {...item} />
          ))}
        </div>

        <SmallMenu except={['pricing', 'reservations']} />
      </div>
    </Title>
  )
}

export default Pricing
