import Image from '../Image'
import { ReactNode } from 'react'

interface LineItemProps {
  title?: string | ReactNode
  children?: React.ReactNode
  indent?: number
  align?: 'left' | 'right'
  pointSrc?: string
  className?: string
}

function LineItem(props: LineItemProps) {
  return (
    <div
      className={`flex w-full ${
        (props.align ?? 'left') === 'right' ? 'justify-end' : ''
      }`}
    >
      <div
        className={`flex flex-row items-start w-full lg:w-1/2 gap-8 lg:gap-0 ${props.className}`}
      >
        <Image
          src={props.pointSrc ?? '/svg/circle.svg'}
          alt="circle"
          className={`w-10 lg:relative z-20`}
          style={{
            left: `-${props.indent ?? 0}rem`,
          }}
        />
        <div className="flex flex-col gap-2">
          {props.title && <span className="text-2xl">{props.title}</span>}
          <span>{props.children}</span>
        </div>
      </div>
    </div>
  )
}

export default LineItem
