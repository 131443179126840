import './styles/index.css'
import './utils/i18n'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Home from './pages/Home'
import Contact from './pages/Contact'
import AboutMe from './pages/AboutMe'
import Faq from './pages/Faq'
import MyApproach from './pages/MyApproach'
import Pricing from './pages/Pricing'

import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: 'contact', element: <Contact /> },
      { path: 'about-me', element: <AboutMe /> },
      { path: 'faq', element: <Faq /> },
      { path: 'my-approach', element: <MyApproach /> },
      { path: 'pricing', element: <Pricing /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
