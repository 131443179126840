import { useState } from 'react'
import FaqItem, { FaqItemProps } from './FaqItem'

interface FaqListProps {
  items: FaqItemProps[]
}

function FaqList({ items }: FaqListProps) {
  const [selected, setSelected] = useState<number | null>(null)

  const handleSelect = (i: number) => {
    if (selected === i) setSelected(null)
    else setSelected(i)
  }

  return (
    <div className="flex flex-col gap-3">
      {items.map((item, i) => (
        <FaqItem
          key={`faq-${i}`}
          {...item}
          selected={selected === i}
          onClick={() => handleSelect(i)}
        />
      ))}
    </div>
  )
}

export default FaqList
