import Config from '../../config/config.json'
import AppointmentButton from '../AppointmentButton'
import SmallMenuItem from './SmallMenuItem'

interface SmallMenuProps {
  except?: string[]
  hideAppointmentButton?: boolean
}

function SmallMenu(props: SmallMenuProps) {
  return (
    <div className="w-full flex flex-col gap-16 items-center mt-14">
      {!props.hideAppointmentButton && <AppointmentButton />}
      <div className="flex flex-wrap gap-12 w-full sm:px-5 justify-center">
        {Config.tiles
          .filter((tile) => !props.except?.includes(tile.title))
          .map((tile) => {
            return <SmallMenuItem {...tile} key={tile.title + 'SmallMenu'} />
          })}
      </div>
    </div>
  )
}

export default SmallMenu
