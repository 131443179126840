import { useContext, useEffect } from 'react'
import { LoaderContext } from '../providers/LoaderProvider'

export const useLoader = (idx?: string | string[]) => {
  const context = useContext(LoaderContext)

  useEffect(() => {
    if (idx || idx?.length) context.load(idx)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx])

  return context
}
